import { useEffect, useState } from "react"
import SidePanelButton from "./SidePanelButton"
import { Link, useLocation, useNavigate } from "react-router-dom"
import MainLogoNd from "assets/svgs/MainLogoNd"
import { useApp } from "core/context/app"
import LogoutButton from "./LogoutButton"
import { CustomScroll } from "react-custom-scroll"
import { STR_DSML, STR_PAID } from "core/constants/strings"
import { isEmpty } from "core/utils/misc"
import { useAuth } from "core/context/auth"
import {
  BookTextIcon,
  CalendarDaysIcon,
  HomeIcon,
  SendHorizonal,
  UserRoundIcon,
  LineChart,
  BriefcaseBusinessIcon,
  BanknoteIcon,
  ArrowLeftRightIcon,
  TrophyIcon,
  HandCoinsIcon,
  MessagesSquareIcon,
  LogOutIcon,
  BookOpenCheckIcon,
} from "lucide-react"
import webEngageTrack from "core/utils/webEngageTrack"

const Sidebar = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { student, activeButton, setActiveButton, isDSML, setIsDSML, remainder } = useApp()
  const { auth, user } = useAuth()

  const [isPaymentDone, setIsPaymentDone] = useState(false)

  const isAccelerator = student?.batch?.includes("Accelerator")
  const isKCE = student?.batch?.includes("KCE")
  const isAC = student?.batch?.includes("AC")
  const isEL = student?.batch?.includes("EL")

  const handleButtonClick = (buttonName: string, url: string) => {
    setActiveButton(buttonName)
    webEngageTrack(url, url)
    navigate(`/${url}`)
  }

  useEffect(() => {
    if (!isEmpty(student) && !isEmpty(auth) && user?.role === "user") {
      setIsDSML(student?.batch?.includes(STR_DSML))
      setIsPaymentDone(user?.payment_status === STR_PAID)
    }
  }, [auth, student])

  const urlMapper: { [key: string]: string } = {
    "/home": "Home",
    "/onboarding": "Onboarding",
    "/payment": "Fee Payment",
    "/transaction": "Transactions",
    "/course": "Curriculum",
    "/mentor": "My Mentor",
    "/schedule": "Class Schedule",
    "/weekly-test-series": "Exam Schedule",
    // "/progress-meeting": "Progress Meet",
    "/leaderboard": "Leaderboard",
    "/placement": "Placement",
    "/store": "Rewards",
    "/refer": "Refer & Earn",
    "/doubt-support": "Doubt Support",
  }

  useEffect(() => {
    const baseRoute: string = Object.keys(urlMapper).find((key) => pathname.includes(key)) || "/home"
    setActiveButton(urlMapper[baseRoute])
  })

  return (
    <div className="flex h-full flex-col gap-y-3 overflow-hidden rounded-lg bg-[#333333] pl-6 pt-6 shadow-[2_4px_8px_0px_rgba(0,0,0,0.40)]">
      <Link to="/home" className="mb-[23px] flex items-start p-[4px] pl-[10px]">
        <MainLogoNd />
      </Link>
      <CustomScroll heightRelativeToParent="100%" className="rounded-lg">
        <div className="flex flex-col gap-y-3 pb-4">
          <SidePanelButton
            name="Home"
            Icon={HomeIcon}
            active={activeButton === "Home"}
            onClick={() => handleButtonClick("Home", "home")}
          />
          {remainder?.hasOwnProperty("Onboarding Meet") && (
            <SidePanelButton
              name="Onboarding"
              Icon={SendHorizonal}
              active={activeButton === "Onboarding"}
              onClick={() => handleButtonClick("Onboarding", "onboarding")}
            />
          )}
          <SidePanelButton
            name="Curriculum"
            Icon={BookTextIcon}
            active={activeButton === "Curriculum"}
            onClick={() => handleButtonClick("Curriculum", "course")}
          />
          {!isKCE && (
            <SidePanelButton
              name="My Mentor"
              Icon={UserRoundIcon}
              active={activeButton === "My Mentor"}
              onClick={() => handleButtonClick("My Mentor", "mentor")}
            />
          )}
          {!isAccelerator && (
            <SidePanelButton
              name="Class Schedule"
              Icon={CalendarDaysIcon}
              active={activeButton === "Class Schedule"}
              onClick={() => handleButtonClick("Class Schedule", "schedule")}
            />
          )}
          {((isKCE && !isDSML) || !isAccelerator) && (
            <SidePanelButton
              name="Exam Schedule"
              Icon={BookOpenCheckIcon}
              active={activeButton === "Exam Schedule"}
              onClick={() => handleButtonClick("Exam Schedule", "weekly-test-series")}
            />
          )}
          {/* {!isKCE && !isAccelerator && (
            <SidePanelButton
              name="Progress Meet"
              Icon={CircleDotDashedIcon}
              active={activeButton === "Progress Meet"}
              onClick={() => handleButtonClick("Progress Meet", "progress-meeting")}
            />
          )} */}
          {!isDSML && !isAccelerator && !student?.tracks?.includes("data_engineer") && (
            <SidePanelButton
              name="Leaderboard"
              Icon={LineChart}
              active={activeButton === "Leaderboard"}
              onClick={() => handleButtonClick("Leaderboard", "leaderboard")}
            />
          )}
          {!isKCE && !isAccelerator && !isAC && (
            <SidePanelButton
              name="Placement"
              Icon={BriefcaseBusinessIcon}
              active={activeButton === "Placement"}
              onClick={() => handleButtonClick("Placement", "placement")}
            />
          )}
          {!isPaymentDone && !isKCE && student && !isEL && (
            <SidePanelButton
              name="Fee Payment"
              Icon={BanknoteIcon}
              active={activeButton === "Fee Payment"}
              onClick={() => handleButtonClick("Fee Payment", "payment")}
            />
          )}
          {!isAccelerator && (
            <SidePanelButton
              name={student?.redeem_coins === true ? "Redeem Coins" : "Store"}
              Icon={TrophyIcon}
              active={activeButton === "Rewards"}
              onClick={() => handleButtonClick("Rewards", student?.redeem_coins === true ? "redeem-coins" : "store")}
            />
          )}
          {!isKCE && (
            <SidePanelButton
              name="Refer & Earn"
              Icon={HandCoinsIcon}
              active={activeButton === "Refer & Earn"}
              onClick={() => handleButtonClick("Refer & Earn", "refer")}
            />
          )}
          <SidePanelButton
            name={`${student?.batch === "KCE" ? "Mentor Support" : "Doubt Support"}`}
            Icon={MessagesSquareIcon}
            active={activeButton === "Doubt Support"}
            onClick={() => handleButtonClick("Doubt Support", "doubt-support")}
          />
          <LogoutButton name="Logout" Icon={LogOutIcon} active={activeButton === "Logout"} />
        </div>
      </CustomScroll>
    </div>
  )
}

export default Sidebar
