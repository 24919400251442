import { cn } from "core/lib/utils"

export default function MonthTabGroup({ buttons, active, onChange, ...props }: any) {
  return (
    <div className="flex flex-wrap items-start gap-[16px]">
      {buttons.map((button: any, i: number) => (
        <button
          {...props}
          id={button}
          key={i}
          className={cn(
            "px-[16px] py-[6px] rounded-[4px] text-[12px] font-[500] font-[Inter]",
            active === button
              ? "hover:bg-[#1c2d6b] bg-[#162456] text-white  shadow-[-1px_1px_2px_0px_rgba(34,41,48,0.05)]"
              : "bg-[#F9F9F9] hover:bg-[#efecec] text-[#646464]"
          )}
          onClick={() => onChange(button,i)}
        >
          {button}
        </button>
      ))}
    </div>
  )
}
